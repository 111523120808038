import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "modal" ]

    connect() {
        const modal = this.modalTarget;
        modal.classList.add("is-visible");
        const closeBtn = modal.querySelector(".close-btn");
        closeBtn.addEventListener("click", () => {
            document.cookie = "cdPopupClosed=true; max-age=86400; path=/";
        });
    }
}